import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import Cgu from './components/Cgu'
import AppointmentsForm from './components/AppointmentsForm'
import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/colors.css'
import './index.css'
import { ParallaxProvider } from 'react-scroll-parallax'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(

    <React.StrictMode>
        <ParallaxProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/cgu" element={<Cgu />} />
                    <Route path="/appointments" element={<AppointmentsForm />} />
                    <Route path="*" element={<App />} />
                </Routes>
            </BrowserRouter>
        </ParallaxProvider>
    </React.StrictMode>

)
