import React from 'react'
import { Fade } from 'react-awesome-reveal'
import { Col, Row } from 'react-bootstrap'
import '../styles/Pricing.css'
import { Parallax } from 'react-scroll-parallax'
import { BrowserView, MobileView } from 'react-device-detect'

function Pricing() {

    return (

        <>

            <BrowserView>
                <Parallax speed={30}>
                    <div id='pricing'>
                        <Fade direction={'down'}>
                            <div id="pricingTitle">
                                Campagne de télémarketing BtoB Immobilier - Habitat & PropTech
                            </div>
                            <div id="pricingSubtitle">
                                Campagne test de minimum 3 mois
                            </div>
                        </Fade>
                        <Row>
                            <Col md={6}>
                                <Fade direction={'left'}>
                                    <div className='pricingBox'>
                                        <div className='pricingBoxTitle'>Call 1</div>
                                        1 chef de projet
                                        <br />
                                        1 téléconseiller dédié
                                        <br />
                                        4 heures par jour, soit 80 heures par mois
                                        <br />
                                        Mise en place, suivi, reporting
                                        <br />
                                        &#187; 1 700 € par mois
                                    </div>
                                </Fade>
                            </Col>
                            <Col md={6}>
                                <Fade direction={'right'}>
                                    <div className='pricingBox'>
                                        <div className='pricingBoxTitle'>Call 2</div>
                                        1 chef de projet
                                        <br />
                                        2 téléconseillers dédiés
                                        <br />
                                        4 heures par jour, soit 160 heures par mois
                                        <br />
                                        Mise en place, suivi, reporting
                                        <br />
                                        &#187; 3 000 € par mois
                                    </div>
                                </Fade>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Fade direction={'left'} delay={300}>
                                    <div className='pricingBox'>
                                        <div className='pricingBoxTitle'>Call </div>
                                        1 chef de projet
                                        <br />
                                        1 téléconseiller dédié
                                        <br />
                                        5 heures par jour, soit 100 heures par mois
                                        <br />
                                        Mise en place, suivi, reporting
                                        <br />
                                        &#187; 2 000 € par mois
                                    </div>
                                </Fade>
                            </Col>
                            <Col md={6}>
                                <Fade direction={'right'} delay={300}>
                                    <div className='pricingBox'>
                                        <div className='pricingBoxTitle'>Call 4</div>
                                        1 chef de projet
                                        <br />
                                        2 téléconseillers dédiés
                                        <br />
                                        5 heures par jour, soit 200 heures par mois
                                        <br />
                                        Mise en place, suivi, reporting
                                        <br />
                                        &#187; 3 600 € par mois
                                    </div>
                                </Fade>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Fade direction={'up'}>
                                    <div id='pricingBoxBottom'>
                                        <div className='pricingBoxTitle'>Options</div>
                                        Dispositif emarketing avec Tracking en amont de la campagne de télémarketing
                                        <br />
                                        | e-mailing 80 € le mille | SMS 250 € le mille | BDD 370 € le mille |
                                    </div>
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                </Parallax>
            </BrowserView>

            <MobileView>
                <Parallax speed={30}>
                    <div id='pricingSmartphone'>
                        <Fade direction={'down'}>
                            <div id="pricingTitle">
                                Campagne de télémarketing BtoB Immobilier - Habitat & PropTech
                            </div>
                            <div id="pricingSubtitle">
                                Campagne test de minimum 3 mois
                            </div>
                        </Fade>
                        <Row>
                            <Col md={6}>
                                <Fade direction={'left'}>
                                    <div className='pricingBox'>
                                        <div className='pricingBoxTitle'>Call 1</div>
                                        1 chef de projet
                                        <br />
                                        1 téléconseiller dédié
                                        <br />
                                        4 heures par jour, soit 80 heures par mois
                                        <br />
                                        Mise en place, suivi, reporting
                                        <br />
                                        &#187; 1 700 € par mois
                                    </div>
                                </Fade>
                            </Col>
                            <Col md={6}>
                                <Fade direction={'right'}>
                                    <div className='pricingBox'>
                                        <div className='pricingBoxTitle'>Call 2</div>
                                        1 chef de projet
                                        <br />
                                        2 téléconseillers dédiés
                                        <br />
                                        4 heures par jour, soit 160 heures par mois
                                        <br />
                                        Mise en place, suivi, reporting
                                        <br />
                                        &#187; 3 000 € par mois
                                    </div>
                                </Fade>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Fade direction={'left'} delay={300}>
                                    <div className='pricingBox'>
                                        <div className='pricingBoxTitle'>Call </div>
                                        1 chef de projet
                                        <br />
                                        1 téléconseiller dédié
                                        <br />
                                        5 heures par jour, soit 100 heures par mois
                                        <br />
                                        Mise en place, suivi, reporting
                                        <br />
                                        &#187; 2 000 € par mois
                                    </div>
                                </Fade>
                            </Col>
                            <Col md={6}>
                                <Fade direction={'right'} delay={300}>
                                    <div className='pricingBox'>
                                        <div className='pricingBoxTitle'>Call 4</div>
                                        1 chef de projet
                                        <br />
                                        2 téléconseillers dédiés
                                        <br />
                                        5 heures par jour, soit 200 heures par mois
                                        <br />
                                        Mise en place, suivi, reporting
                                        <br />
                                        &#187; 3 600 € par mois
                                    </div>
                                </Fade>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Fade direction={'up'}>
                                    <div id='pricingBoxBottom'>
                                        <div className='pricingBoxTitle'>Options</div>
                                        Dispositif emarketing avec Tracking en amont de la campagne de télémarketing
                                        <br />
                                        | e-mailing 80 € le mille | SMS 250 € le mille | BDD 370 € le mille |
                                    </div>
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                </Parallax>
            </MobileView>

        </>

    )

}

export default Pricing