import React from 'react'
import { BrowserView, MobileView } from 'react-device-detect'
import { Fade } from 'react-awesome-reveal'
import headerImage from '../assets/headerImage.png'
import headerIcon from '../assets/headerIcon.png'
import '../styles/Solution.css'

function Solution() {

    return (

        <>

            <BrowserView>
                <div id='solution'>
                    <Fade direction={'up'}>
                        <div id='solutionTitle'>
                            Notre équipe de téléconseillers expérimentés réalise pour vous des opérations de prospection téléphonique
                            <br />
                            auprès des professionnels de l’immobilier, de l’habitat et des PropTech
                        </div>
                    </Fade>
                    <div id='solutionText'>
                        <Fade direction={'right'}>
                            <div id='solutionTextLeft'>
                                <div id='solutionTextLeftTitle'>
                                    Campagne de télémarketing
                                    <br />
                                    BtoB Immobilier - Habitat & PropTech
                                </div>
                                <ul>
                                    <li><b>Détection</b> de projet</li>
                                    <li><b>Prise de rendez-vous</b> qualifié</li>
                                    <li><b>Présentation</b>, de service, de produit, de dispositif</li>
                                    <li><b>Démonstration</b>, de service, de produit, de dispositif</li>
                                    <li><b>Inscription</b> de nouveau client</li>
                                    <li><b>Acquisition</b> de leads </li>
                                    <li><b>Qualification</b> de fichier</li>
                                    <li><b>Relance</b>, de client, de prospect</li>
                                    <li><b>Fidélisation</b>, de client, de prospect</li>
                                    <li><b>Organisation</b> ou suivi d’<b>événements</b> (webinars, salons…)</li>
                                    <li><b>Enquête, questionnaire, étude de marché, testing</b></li>
                                </ul>
                            </div>
                        </Fade>
                        <Fade direction={'right'}>
                            <div id='solutionTextRight'>
                                <div className='solutionTextRightParagraph'>
                                    Des Téléconseillers dédiés spécialistes du secteur de l’immobilier
                                </div>
                                <div className='solutionTextRightParagraph'>
                                    Une expertise de plus de 15 ans
                                </div>
                                <div className='solutionTextRightParagraph'>
                                    Des Bases de Données qualifiées
                                </div>
                                <div className='solutionTextRightParagraph'>
                                    Des statistiques et des KPI avec un reporting hebdo
                                </div>
                            </div>
                        </Fade>
                    </div>
                    <Fade direction='right'>
                        <img src={headerImage} id='solutionImage' alt="headerImage" />
                    </Fade>
                    <Fade direction='left'>
                        <div id='solutionBand'>
                            <img src={headerIcon} id='solutionBandIcon' alt="headerIcon" />
                            <div id='solutionBandText'>
                                CAPTEZ et GAGNER de nouveaux CLIENTS
                                <br />
                                ACCELEREZ votre DEVELOPPEMENT
                            </div>
                        </div>
                    </Fade>
                </div>
            </BrowserView>

            <MobileView>
                <div id='solutionSmartphone'>
                    <Fade direction={'up'}>
                        <div id='solutionSmartphoneTitle'>
                            Notre équipe de téléconseillers expérimentés réalise pour vous des opérations de prospection téléphonique
                            <br />
                            auprès des professionnels de l’immobilier, de l’habitat et des PropTech
                        </div>
                    </Fade>
                    <Fade direction={'right'}>
                        <div id='solutionSmartphoneTextLeft'>
                            <div id='solutionSmartphoneTextLeftTitle'>
                                Campagne de télémarketing
                                <br />
                                BtoB Immobilier - Habitat & PropTech
                            </div>
                            <ul>
                                <li><b>Détection</b> de projet</li>
                                <li><b>Prise de rendez-vous</b> qualifié</li>
                                <li><b>Présentation</b>, de service, de produit, de dispositif</li>
                                <li><b>Démonstration</b>, de service, de produit, de dispositif</li>
                                <li><b>Inscription</b> de nouveau client</li>
                                <li><b>Acquisition</b> de leads </li>
                                <li><b>Qualification</b> de fichier</li>
                                <li><b>Relance</b>, de client, de prospect</li>
                                <li><b>Fidélisation</b>, de client, de prospect</li>
                                <li><b>Organisation</b> ou suivi d’<b>événements</b> (webinars, salons…)</li>
                                <li><b>Enquête, questionnaire, étude de marché, testing</b></li>
                            </ul>
                        </div>
                    </Fade>
                    <Fade direction={'right'}>
                        <div id='solutionSmartphoneTextRight'>
                            <div className='solutionSmartphoneTextRightParagraph'>
                                Des Téléconseillers dédiés spécialistes du secteur de l’immobilier
                            </div>
                            <div className='solutionSmartphoneTextRightParagraph'>
                                Une expertise de plus de 15 ans
                            </div>
                            <div className='solutionSmartphoneTextRightParagraph'>
                                Des Bases de Données qualifiées
                            </div>
                            <div className='solutionSmartphoneTextRightParagraph'>
                                Des statistiques et des KPI avec un reporting hebdo
                            </div>
                        </div>
                    </Fade>
                    <Fade direction='right'>
                        <img src={headerImage} id='solutionSmartphoneImage' alt="headerImage" />
                    </Fade>
                    <Fade direction='left'>
                        <div id='solutionSmartphoneBand'>
                            <img src={headerIcon} id='solutionSmartphoneBandIcon' alt="headerIcon" />
                            <div id='solutionSmartphoneBandText'>
                                CAPTEZ et GAGNER de nouveaux CLIENTS
                                <br />
                                ACCELEREZ votre DEVELOPPEMENT
                            </div>
                        </div>
                    </Fade>
                </div>
            </MobileView>

        </>

    )

}

export default Solution