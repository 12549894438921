import React, { useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import Contact from './components/Contact'
import Footer from './components/Footer'
import Header from './components/Header'
import Nav from './components/Nav'
import Presentation from './components/Presentation'
import Pricing from './components/Pricing'
import Solution from './components/Solution'
import 'react-toastify/dist/ReactToastify.css'
import './App.css'

function App() {

	useEffect(() => {
		if (window.location.hash == '#presentation') {
			window.scrollTo({ top: 550 })
		}
		if (window.location.hash == '#solution') {
			window.scrollTo({ top: 1050 })
		}
		if (window.location.hash == '#pricing') {
			window.scrollTo({ top: 2180 })
		}
		if (window.location.hash == '#contact') {
			window.scrollTo({ top: 3100 })
		}
	}, [])

    return (

        <div className="App">
            <Nav />
            <Header />
            <Presentation />
            <Solution />
            <Pricing />
            <Contact />
            <Footer />
            <ToastContainer position='top-left' theme='light' />
        </div>

    )

}

export default App
