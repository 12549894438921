import React, { useRef, useState } from 'react'
import InputSwitchSelector from './Input/InputSwitchSelector'
import { BrowserView, MobileView } from 'react-device-detect'
import { toast } from 'react-toastify'
import { RotatingLines } from 'react-loader-spinner'
import { Fade } from 'react-awesome-reveal'
import { Col, Form, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faEnvelope, faPen, faPhone, faUser } from '@fortawesome/free-solid-svg-icons'
import '../styles/Contact.css'
import InputText from './Input/InputText'
import contactImage from '../assets/contactImage.jpg'
import InputTextarea from './Input/InputTextarea'

function Contact() {

    const [isLoading, setIsLoading] = useState(false)
    const [gender, setGender] = useState("M")
    const lastnameRef = useRef(null)
    const [lastname, setLastname] = useState("")
    const [lastnameError, setLastnameError] = useState(null)
    const firstnameRef = useRef(null)
    const [firstname, setFirstname] = useState("")
    const [firstnameError, setFirstnameError] = useState(null)
    const emailRef = useRef(null)
    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState(null)
    const phoneRef = useRef(null)
    const [phone, setPhone] = useState("")
    const [phoneError, setPhoneError] = useState(null)
    const companyRef = useRef(null)
    const [company, setCompany] = useState("")
    const [companyError, setCompanyError] = useState(null)
    const descriptionRef = useRef(null)
    const [description, setDescription] = useState("")
    const [descriptionError, setDescriptionError] = useState(null)

    const switchSelectorGenderData = [
        {
            label: "M.",
            value: "M",
        }, {
            label: "Mme.",
            value: "F",
        }
    ]

    function contact() {

        setIsLoading(true)
        var errorMessage = ""
        const toastId = toast.loading("Inscription...")
        if (!lastnameRef.current.nameValidation(lastname)) {
            errorMessage += "Le nom est incorrect | "
        }
        if (!firstnameRef.current.nameValidation(firstname)) {
            errorMessage += "Le prénom est incorrect | "
        }
        if (!emailRef.current.emailValidation(email)) {
            errorMessage += "L'email est incorrect | "
        }
        var phoneFormat = null
        if (!phoneRef.current.phoneValidation(phone)) {
            errorMessage += "Le téléphone est incorrect | "
        } else {
            phoneFormat = phone.replace(/\s+/g, '')
        }
        if (!companyRef.current.textValidation(company)) {
            errorMessage += "La raison sociale est incorrecte | "
        }
        if (!descriptionRef.current.textValidation(description)) {
            errorMessage += "La description du projet est introuvable | "
        }
        let message = "Bonjour,\nvous trouverez ci joint une demande d'information provenant du site call-proimmo.fr.\n\n" +
            gender +
            " " +
            firstname +
            " " +
            lastname +
            "\nTéléphone : " + phoneFormat +
            "\nAdresse e-mail : " + email +
            "\nRaison sociale : " + company +
            "\nMessage complémentaire : " + description + "\n\n" +
            "Toute l'équipe d'Acheter-louer.fr"
        if (errorMessage) {
            toast.update(toastId, { render: errorMessage, type: "error", isLoading: false, autoClose: 5000 })
            setIsLoading(false)
        } else {
            const to = process.env.NODE_ENV === 'production' ? 'contact@call-proimmo.fr' : 'support@acheter-louer.fr'
            const cc = process.env.NODE_ENV === 'production' ? ['support@acheter-louer.fr', 'contact@call-proimmo.fr'] : 'support@acheter-louer.fr'
            const formDataWithConsts = {
                lastname: lastname,
                firstname: firstname,
                gender: gender,
                phone: phoneFormat,
                email: email,
                raisonSociale: company,
                message: message,
                to,
                cc,
                timestamp: Date.now(),
                ca: 'g17382',
                subject: "[call-proimmo.fr] - Demande d'information",
                formType: "information",
                withAttachements: false,
                form_mandatory_fields: "gender,lastname,firstname,raisonSociale,email,phone"
            }
            fetch('https://landings.acheterlouerpro.fr/api/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formDataWithConsts),
            }).then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok')
                }
                return response.json()
            }).then((data) => {
                if (data.status) {
                    throw new Error(data.message)
                } else {
                    toast.update(toastId, { render: "Message envoyé !", type: "success", isLoading: false, autoClose: 5000 })
                    setGender("M")
                    setLastname("")
                    setFirstname("")
                    setPhone("")
                    setEmail("")
                    setCompany("")
                    setDescription("")
                    setIsLoading(false)
                }
            }).catch((error) => {
                toast.update(toastId, { render: error.toString(), type: "error", isLoading: false, autoClose: 5000 })
                console.error('Error sending form data:', error)
                setIsLoading(false)
            })

        }
    }

    return (

        <>

            <BrowserView>
                <div id='contact'>
                    <Row>
                        <Col md={4}>
                            <Fade direction={'left'}>
                                <div id="contactLeft">
                                    <div id='contactLeftTitle'>
                                        CONTACTEZ-NOUS
                                    </div>
                                    <div className='contactLeftText'>
                                        <FontAwesomeIcon icon={faEnvelope} className='contactLeftTextIcon' />contact@call-proimmo.fr
                                    </div>
                                    <div className='contactLeftText'>
                                        <a href="tel:0160929612"><FontAwesomeIcon icon={faPhone} className='contactLeftTextIcon' />01 60 92 96 12</a>
                                    </div>
                                    <img src={contactImage} alt="contactImage" id='contactLeftImage' />
                                </div>
                            </Fade>
                        </Col>
                        <Col md={8}>
                            <Fade direction={'right'}>
                                <Form id="contactForm">
                                    <Row>
                                        <Col>
                                            <InputSwitchSelector
                                                data={switchSelectorGenderData}
                                                setValue={setGender}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <InputText
                                                type={'name'}
                                                ref={lastnameRef}
                                                text={lastname} setText={setLastname}
                                                textError={lastnameError} setTextError={setLastnameError}
                                                icon={<FontAwesomeIcon icon={faUser} />}
                                                placeholder={"Nom"}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <InputText
                                                type={'name'}
                                                ref={firstnameRef}
                                                text={firstname} setText={setFirstname}
                                                textError={firstnameError} setTextError={setFirstnameError}
                                                icon={<FontAwesomeIcon icon={faUser} />}
                                                placeholder={"Prénom"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <InputText
                                                type={'email'}
                                                ref={emailRef}
                                                text={email} setText={setEmail}
                                                textError={emailError} setTextError={setEmailError}
                                                icon={<FontAwesomeIcon icon={faEnvelope} />}
                                                placeholder={"Email"}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <InputText
                                                type={'phone'}
                                                ref={phoneRef}
                                                text={phone} setText={setPhone}
                                                textError={phoneError} setTextError={setPhoneError}
                                                icon={<FontAwesomeIcon icon={faPhone} />}
                                                placeholder={"Téléphone"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <InputText
                                                type={'text'}
                                                ref={companyRef}
                                                text={company} setText={setCompany}
                                                textError={companyError} setTextError={setCompanyError}
                                                icon={<FontAwesomeIcon icon={faBuilding} />}
                                                placeholder={"Raison sociale"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <InputTextarea
                                                ref={descriptionRef}
                                                text={description} setText={setDescription}
                                                textError={descriptionError} setTextError={setDescriptionError}
                                                icon={<FontAwesomeIcon icon={faPen} />}
                                                placeholder={"Parlez nous de votre projet"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div id='contactFormButtonContainer'>
                                                {!isLoading ?
                                                    <div id='contactFormButton' onClick={() => contact()}>
                                                        Envoyer
                                                    </div>
                                                    :
                                                    <RotatingLines
                                                        strokeColor="#58815a"
                                                        strokeWidth="5"
                                                        animationDuration="1"
                                                        width="30"
                                                        visible={true}
                                                    />
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </Fade>
                        </Col>
                    </Row>
                </div>
            </BrowserView>

            <MobileView>
                <div id='contactSmartphone'>
                    <Row>
                        <Col md={4}>
                            <Fade direction={'left'}>
                                <div id="contactSmartphoneLeft">
                                    <div id='contactSmartphoneLeftTitle'>
                                        CONTACTEZ-NOUS
                                    </div>
                                    <div className='contactSmartphoneLeftText'>
                                        <FontAwesomeIcon icon={faEnvelope} className='contactLeftTextIcon' />contact@call-proimmo.fr
                                    </div>
                                    <div className='contactSmartphoneLeftText'>
                                        <a href="tel:0160929612"><FontAwesomeIcon icon={faPhone} className='contactLeftTextIcon' />01 60 92 96 12</a>
                                    </div>
                                    <img src={contactImage} alt="contactImage" id='contactSmartphoneLeftImage' />
                                </div>
                            </Fade>
                        </Col>
                        <Col md={8}>
                            <Fade direction={'right'}>
                                <Form id="contactSmartphoneForm">
                                    <Row>
                                        <Col>
                                            <InputSwitchSelector
                                                data={switchSelectorGenderData}
                                                setValue={setGender}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <InputText
                                                type={'name'}
                                                ref={lastnameRef}
                                                text={lastname} setText={setLastname}
                                                textError={lastnameError} setTextError={setLastnameError}
                                                icon={<FontAwesomeIcon icon={faUser} />}
                                                placeholder={"Nom"}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <InputText
                                                type={'name'}
                                                ref={firstnameRef}
                                                text={firstname} setText={setFirstname}
                                                textError={firstnameError} setTextError={setFirstnameError}
                                                icon={<FontAwesomeIcon icon={faUser} />}
                                                placeholder={"Prénom"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <InputText
                                                type={'email'}
                                                ref={emailRef}
                                                text={email} setText={setEmail}
                                                textError={emailError} setTextError={setEmailError}
                                                icon={<FontAwesomeIcon icon={faEnvelope} />}
                                                placeholder={"Email"}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <InputText
                                                type={'phone'}
                                                ref={phoneRef}
                                                text={phone} setText={setPhone}
                                                textError={phoneError} setTextError={setPhoneError}
                                                icon={<FontAwesomeIcon icon={faPhone} />}
                                                placeholder={"Téléphone"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <InputText
                                                type={'text'}
                                                ref={companyRef}
                                                text={company} setText={setCompany}
                                                textError={companyError} setTextError={setCompanyError}
                                                icon={<FontAwesomeIcon icon={faBuilding} />}
                                                placeholder={"Raison sociale"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <InputTextarea
                                                ref={descriptionRef}
                                                text={description} setText={setDescription}
                                                textError={descriptionError} setTextError={setDescriptionError}
                                                icon={<FontAwesomeIcon icon={faPen} />}
                                                placeholder={"Parlez nous de votre projet"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div id='contactFormButtonContainer'>
                                                <div id='contactFormButton' onClick={() => contact()}>
                                                    Envoyer
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </Fade>
                        </Col>
                    </Row>
                </div>
            </MobileView>

        </>

    )

}

export default Contact