import React, { forwardRef, useImperativeHandle } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faT } from '@fortawesome/free-solid-svg-icons'
import '../../styles/Input/InputText.css'

const InputText = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        textValidation: (text) => textValidation(text),
        nameValidation: (name) => nameValidation(name),
        emailValidation: (email) => emailValidation(email),
        phoneValidation: (phone) => phoneValidation(phone),
        addressStreetValidation: (street) => addressStreetValidation(street),
        addressCityValidation: (city) => addressCityValidation(city),
        addressZipcodeValidation: (zipcode) => addressZipcodeValidation(zipcode)
    }))

    function onChangeValidation(text) {

        if (props.type === 'text') {
            textValidation(text)
        } else if (props.type === 'name') {
            nameFormat(text)
            nameValidation(text)
        } else if (props.type === 'email') {
            emailFormat(text)
            emailValidation(text)
        } else if (props.type === 'phone') {
            phoneValidation(text)
        } else if (props.type === 'addressStreet') {
            addressStreetFormat(text)
            addressStreetValidation(text)
        } else if (props.type === 'addressCity') {
            addressCityFormat(text)
            addressCityValidation(text)
        } else if (props.type === 'addressZipcode') {
            addressZipcodeValidation(text)
        }

    }

    function textValidation(text) {

        if (text) {
            props.setTextError(false)
            return true
        } else {
            props.setTextError(true)
            return false
        }

    }

    function nameValidation(name) {

        const nameRegex = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
        if (name && nameRegex.test(name)) {
            props.setTextError(false)
            return true
        } else {
            props.setTextError(true)
            return false
        }

    }

    function nameFormat(name) {

        if (name) {
            var nameFormat = name.toLowerCase().split(' ').map((item) => item.charAt(0).toUpperCase() + item.substring(1)).join(' ')
            nameFormat = nameFormat.split('-').map((item) => item.charAt(0).toUpperCase() + item.substring(1)).join('-')
            nameFormat = nameFormat.split('\'').map((item) => item.charAt(0).toUpperCase() + item.substring(1)).join('\'')
            props.setText(nameFormat)
        }

    }

    function emailFormat(email) {

        if (email) {
            props.setText(email.toLowerCase().trim())
        }

    }

    function emailValidation(email) {

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (email && emailRegex.test(email)) {
            props.setTextError(false)
            return true
        } else {
            props.setTextError(true)
            return false
        }

    }

    function phoneValidation(phone) {

        const phoneRegex = /^[0-9 ]+$/
        if (phone && (phone.length === 10 || phone.length === 14) && phoneRegex.test(phone)) {
            props.setTextError(false)
            return true
        } else {
            props.setTextError(true)
            return false
        }

    }

    function addressStreetFormat(street) {

        const streetFormat = street.toLowerCase()
        props.setText(streetFormat)

    }

    function addressStreetValidation(addressStreet) {

        const addressStreetRegex = /^[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
        if (addressStreet && addressStreetRegex.test(addressStreet)) {
            props.setTextError(false)
            return true
        } else {
            props.setTextError(true)
            return false
        }

    }

    function addressCityFormat(addressCity) {

        if (addressCity) {
            var addressCityFormat = addressCity.toLowerCase().split(' ').map((item) => item.charAt(0).toUpperCase() + item.substring(1)).join(' ')
            addressCityFormat = addressCityFormat.split('-').map((item) => item.charAt(0).toUpperCase() + item.substring(1)).join('-')
            addressCityFormat = addressCityFormat.split('\'').map((item) => item.charAt(0).toUpperCase() + item.substring(1)).join('\'')
            props.setText(addressCityFormat)
        }

    }

    function addressCityValidation(addressCity) {

        const addressCityRegex = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
        if (addressCity && addressCityRegex.test(addressCity)) {
            props.setTextError(false)
            return true
        } else {
            props.setTextError(true)
            return false
        }

    }

    function addressZipcodeValidation(zipcode) {

        const zipcodeRegex = /^[0-9]+$/
        if (zipcode && zipcode.length === 5 && zipcodeRegex.test(zipcode)) {
            props.setTextError(false)
            return true
        } else {
            props.setTextError(true)
            return false
        }

    }

    return (

        <InputGroup>
            <InputGroup.Text className={props.textError == null ? 'inputTextIcon' : !props.textError ? 'inputTextIconSuccess' : 'inputTextIconError'}>
                {props.icon ? props.icon : <FontAwesomeIcon icon={faT} />}
            </InputGroup.Text>
            <Form.Control
                type={'text'}
                placeholder={props.placeholder}
                value={props.text}
                onChange={(e) => { props.setText(e.target.value); onChangeValidation(e.target.value) }}
                className={props.textError == null ? 'inputText' : !props.textError ? 'inputTextSuccess' : 'inputTextError'}
                onKeyPress={(event) => props.onPressEnter && event.key === "Enter" ? props.onPressEnter() : null}
            />
        </InputGroup>

    )

})

export default InputText
