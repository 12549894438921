import React, { useState } from 'react'
import { BrowserView, MobileView } from 'react-device-detect'
import { Fade } from 'react-awesome-reveal'
import logo from '../assets/logo.png'
import '../styles/Nav.css'

function Nav() {

    const [smartphoneMenuDisplay, setSmartphoneMenuDisplay] = useState(false)

    return (

        <>

            <BrowserView>
                <nav id='nav'>
                    <Fade direction={'left'}>
                        <div id='navLogo' onClick={() => window.scrollTo({ top: 0 })}>
                            <img src={logo} height={"40px"} alt={"logo"} />
                        </div>
                    </Fade>
                    <Fade direction={'right'}>
                        <div id='navLinks'>
                            <div className="navLink" onClick={() => window.scrollTo({ top: 550 })}>Présentation</div>
                            <div className="navLink" onClick={() => window.scrollTo({ top: 1050 })}>Nos solutions</div>
                            <div className="navLink" onClick={() => window.scrollTo({ top: 2180 })}>Tarifs</div>
                            <div className="navLink" onClick={() => window.scrollTo({ top: 3100 })}>Contact</div>
                        </div>
                    </Fade>
                </nav>
            </BrowserView>

            <MobileView>
                <nav id='nav'>
                    <div id='navLogo' onClick={() => window.scrollTo({ top: 0 })}>
                        <img src={logo} height={"30px"} alt={"logo"} />
                    </div>
                    <div className={smartphoneMenuDisplay ? "navSmartphoneBurglar is-open" : "navSmartphoneBurglar is-closed"} onClick={() => setSmartphoneMenuDisplay(!smartphoneMenuDisplay)}>
                        <div className="navSmartphoneBurgerIcon">
                            <div className="navSmartphoneBurgerIconContainer">
                                <span className="navSmartphoneBurgerBunTop"></span>
                                <span className="navSmartphoneBurgerFilling"></span>
                                <span className="navSmartphoneBurgerBunBot"></span>
                            </div>
                        </div>
                        <div className="burger-ring">
                            <svg className="svg-ring">
                                <path className="path" fill="none" stroke="#000000" strokeMiterlimit="10" strokeWidth="4" d="M 34 2 C 16.3 2 2 16.3 2 34 s 14.3 32 32 32 s 32 -14.3 32 -32 S 51.7 2 34 2" />
                            </svg>
                        </div>
                        <svg width="0" height="0">
                            <mask id="mask">
                                <path xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#ff0000" strokeMiterlimit="10" strokeWidth="4" d="M 34 2 c 11.6 0 21.8 6.2 27.4 15.5 c 2.9 4.8 5 16.5 -9.4 16.5 h -4" />
                            </mask>
                        </svg>
                        <div className="path-burger">
                            <div className="animate-path">
                                <div className="path-rotation"></div>
                            </div>
                        </div>
                    </div>
                    {smartphoneMenuDisplay ?
                        <div id='navSmartphoneBurger'>
                            <div className="navSmartphoneBurgerLink" onClick={() => { setSmartphoneMenuDisplay(false); window.scrollTo({ top: 600 }) }}>Présentation</div>
                            <div className="navSmartphoneBurgerLink" onClick={() => { setSmartphoneMenuDisplay(false); window.scrollTo({ top: 1630 }) }}>Nos solutions</div>
                            <div className="navSmartphoneBurgerLink" onClick={() => { setSmartphoneMenuDisplay(false); window.scrollTo({ top: 3280 }) }}>Tarifs</div>
                            <div className="navSmartphoneBurgerLink" onClick={() => { setSmartphoneMenuDisplay(false); window.scrollTo({ top: 5000 }) }}>Contact</div>
                        </div>
                        : null
                    }
                </nav>
            </MobileView>

        </>

    )

}

export default Nav
