import React, { useRef, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import InputSwitchSelector from './Input/InputSwitchSelector'
import InputLargeSwitchSelector from './Input/InputLargeSwitchSelector'
import { BrowserView, MobileView } from 'react-device-detect'
import { toast } from 'react-toastify'
import { RotatingLines } from 'react-loader-spinner'
import { Fade } from 'react-awesome-reveal'
import { Col, Form, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faCalendarDays, faCity, faClock, faEnvelope, faGlobe, faPen, faPhone, faUser, faUserDoctor } from '@fortawesome/free-solid-svg-icons'
import InputText from './Input/InputText'
import contactImage from '../assets/contactImage.jpg'
import InputTextarea from './Input/InputTextarea'
import axios from 'axios'

function AppointmentsForm() {

    const [isLoading, setIsLoading] = useState(false)
    const [gender, setGender] = useState("M")
    const [commercial, setCommercial] = useState(133)
    const lastnameRef = useRef(null)
    const [lastname, setLastname] = useState("")
    const [lastnameError, setLastnameError] = useState(null)
    const firstnameRef = useRef(null)
    const [firstname, setFirstname] = useState("")
    const [firstnameError, setFirstnameError] = useState(null)
    const emailRef = useRef(null)
    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState(null)
    const phoneRef = useRef(null)
    const [phone, setPhone] = useState("")
    const [phoneError, setPhoneError] = useState(null)
    const agencyRef = useRef(null)
    const [agency, setAgency] = useState("")
    const [agencyError, setAgencyError] = useState(null)
    const observationsRef = useRef(null)
    const [observations, setObservations] = useState("")
    const [observationsError, setObservationsError] = useState(null)


    const daysRef = useRef(null)
    const [days, setDays] = useState("")
    const [daysError, setDaysError] = useState(null)
    const timeRef = useRef(null)
    const [time, setTime] = useState("")
    const [timeError, setTimeError] = useState(null)
    const fonctionRef = useRef(null)
    const [fonction, setFonction] = useState("")
    const [fonctionError, setFonctionError] = useState(null)
    const cpRef = useRef(null)
    const [cp, setCp] = useState("")
    const [cpError, setCpError] = useState(null)
    const cityRef = useRef(null)
    const [city, setCity] = useState("")
    const [cityError, setCityError] = useState(null)

    const switchSelectorGenderData = [
        {
            label: "M.",
            value: "M",
        }, {
            label: "Mme.",
            value: "Mme",
        }
    ]

    const switchSelectorCommercial = [
        {
            label: "Laurent Bias",
            value: 133,
        }, {
            label: "Julien Tellier",
            value: 48,
        }, {
            label: "Virginie Calef",
            value: 23,
        }
    ]

    function sendAppointment() {

        setIsLoading(true)
        var errorMessage = []
        const toastId = toast.loading("Inscription...")
        if (!lastnameRef.current.nameValidation(lastname)) {
            errorMessage.push("Le nom est incorrect")
        }
        if (!firstnameRef.current.nameValidation(firstname)) {
            errorMessage.push("Le prénom est incorrect")
        }
        if (!emailRef.current.emailValidation(email)) {
            errorMessage.push("L'email est incorrect")
        }
        var phoneFormat = null
        if (!phoneRef.current.phoneValidation(phone)) {
            errorMessage.push("Le téléphone est incorrect")
        } else {
            phoneFormat = phone.replace(/\s+/g, '')
        }
        if (!cityRef.current.textValidation(city)) {
          errorMessage.push("La ville est incorrecte")
        }
        if (!cpRef.current.textValidation(cp)) {
            errorMessage.push("Le code postal est incorrect")
        }
        if (!agencyRef.current.textValidation(agency)) {
            errorMessage.push("L'agence est incorrecte")
        }
        if (!fonctionRef.current.textValidation(fonction)) {
            errorMessage.push("La fonction est incorrecte")
        }
        if (!daysRef.current.textValidation(days)) {
            errorMessage.push("La date n'est pas renseignée")
        }
        if (!timeRef.current.textValidation(time)) {
            errorMessage.push("L'heure n'est pas renseignée")
        }
        if (errorMessage.length) {
            toast.update(toastId, { render: errorMessage.join(' | '), type: "error", isLoading: false, autoClose: 5000 })
            setIsLoading(false)
        } else {
            const backend = 'https://www.call-proimmo.fr/api/appointment'
            axios.post(backend, {
                callback_day: days,
                callback_time_slot: time,
                agency_name: agency,
                gender: gender,
                firstname: firstname,
                lastname: lastname,
                function: fonction,
                zipcode: cp,
                city: city,
                email: email,
                phone: phoneFormat,
                observations: observations,
                id_com: commercial
            }).then(response => {
              toast.update(toastId, { render: "Message envoyé !", type: "success", isLoading: false, autoClose: 5000 })
              setIsLoading(false)
            }).catch(error => {
              toast.update(toastId, { render: error.toString(), type: "error", isLoading: false, autoClose: 5000 })
              console.error('Error sending form data:', error)
              setIsLoading(false)
            })
        }
    }

    return (

        <>

            <BrowserView>
                <div style={{ marginTop: 15, marginBottom: 15 }}>
                    <Row>
                        <Col md={4}>
                            <Fade direction={'left'}>
                                <div id="contactLeft">
                                    <div id='contactLeftTitle'>
                                        PREVOIR UN RENDEZ-VOUS
                                    </div>
                                    <img src={contactImage} alt="contactImage" id='contactLeftImage' />
                                </div>
                            </Fade>
                        </Col>
                        <Col md={8}>
                            <Fade direction={'right'}>
                                <Form id="contactForm">
                                    <Row>
                                        <Col>
                                            <InputSwitchSelector
                                                data={switchSelectorGenderData}
                                                setValue={setGender}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <InputLargeSwitchSelector
                                                data={switchSelectorCommercial}
                                                setValue={setCommercial}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <InputText
                                                type={'name'}
                                                ref={lastnameRef}
                                                text={lastname} setText={setLastname}
                                                textError={lastnameError} setTextError={setLastnameError}
                                                icon={<FontAwesomeIcon icon={faUser} />}
                                                placeholder={"Nom"}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <InputText
                                                type={'name'}
                                                ref={firstnameRef}
                                                text={firstname} setText={setFirstname}
                                                textError={firstnameError} setTextError={setFirstnameError}
                                                icon={<FontAwesomeIcon icon={faUser} />}
                                                placeholder={"Prénom"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <InputText
                                                type={'email'}
                                                ref={emailRef}
                                                text={email} setText={setEmail}
                                                textError={emailError} setTextError={setEmailError}
                                                icon={<FontAwesomeIcon icon={faEnvelope} />}
                                                placeholder={"Email"}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <InputText
                                                type={'phone'}
                                                ref={phoneRef}
                                                text={phone} setText={setPhone}
                                                textError={phoneError} setTextError={setPhoneError}
                                                icon={<FontAwesomeIcon icon={faPhone} />}
                                                placeholder={"Téléphone"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <InputText
                                                type={'city'}
                                                ref={cityRef}
                                                text={city} setText={setCity}
                                                textError={cityError} setTextError={setCityError}
                                                icon={<FontAwesomeIcon icon={faCity} />}
                                                placeholder={"Ville"}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <InputText
                                                type={'cp'}
                                                ref={cpRef}
                                                text={cp} setText={setCp}
                                                textError={cpError} setTextError={setCpError}
                                                icon={<FontAwesomeIcon icon={faGlobe} />}
                                                placeholder={"Code postal"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <InputText
                                                type={'text'}
                                                ref={agencyRef}
                                                text={agency} setText={setAgency}
                                                textError={agencyError} setTextError={setAgencyError}
                                                icon={<FontAwesomeIcon icon={faBuilding} />}
                                                placeholder={"Agence"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <InputText
                                                type={'text'}
                                                ref={fonctionRef}
                                                text={fonction} setText={setFonction}
                                                textError={fonctionError} setTextError={setFonctionError}
                                                icon={<FontAwesomeIcon icon={faUserDoctor} />}
                                                placeholder={"Fonction"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <InputText
                                                type={'text'}
                                                ref={daysRef}
                                                text={days} setText={setDays}
                                                textError={daysError} setTextError={setDaysError}
                                                icon={<FontAwesomeIcon icon={faCalendarDays} />}
                                                placeholder={"Jour(s)"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <InputText
                                                type={'text'}
                                                ref={timeRef}
                                                text={time} setText={setTime}
                                                textError={timeError} setTextError={setTimeError}
                                                icon={<FontAwesomeIcon icon={faClock} />}
                                                placeholder={"Heure de rendez-vous"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <InputTextarea
                                                ref={observationsRef}
                                                text={observations} setText={setObservations}
                                                textError={observationsError} setTextError={setObservationsError}
                                                icon={<FontAwesomeIcon icon={faPen} />}
                                                placeholder={"Observations supplémentaires"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div id='contactFormButtonContainer'>
                                                {!isLoading ?
                                                    <div id='contactFormButton' onClick={() => sendAppointment()}>
                                                        Envoyer
                                                    </div>
                                                    :
                                                    <RotatingLines
                                                        strokeColor="#58815a"
                                                        strokeWidth="5"
                                                        animationDuration="1"
                                                        width="30"
                                                        visible={true}
                                                    />
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </Fade>
                        </Col>
                    </Row>
                </div>
            </BrowserView>

            <MobileView>
                <div id='contactSmartphone'>
                    <Row>
                        <Col md={4}>
                            <div id="contactSmartphoneLeft">
                                <div id='contactSmartphoneLeftTitle'>
                                    PREVOIR UN RENDEZ-VOUS
                                </div>
                            </div>
                        </Col>
                        <Col md={8}>
                            <Fade direction={'right'}>
                                <Form id="contactSmartphoneForm">
                                    <Row>
                                        <Col>
                                            <InputSwitchSelector
                                                data={switchSelectorGenderData}
                                                setValue={setGender}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <InputLargeSwitchSelector
                                                data={switchSelectorCommercial}
                                                setValue={setCommercial}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <InputText
                                                type={'name'}
                                                ref={lastnameRef}
                                                text={lastname} setText={setLastname}
                                                textError={lastnameError} setTextError={setLastnameError}
                                                icon={<FontAwesomeIcon icon={faUser} />}
                                                placeholder={"Nom"}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <InputText
                                                type={'name'}
                                                ref={firstnameRef}
                                                text={firstname} setText={setFirstname}
                                                textError={firstnameError} setTextError={setFirstnameError}
                                                icon={<FontAwesomeIcon icon={faUser} />}
                                                placeholder={"Prénom"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <InputText
                                                type={'email'}
                                                ref={emailRef}
                                                text={email} setText={setEmail}
                                                textError={emailError} setTextError={setEmailError}
                                                icon={<FontAwesomeIcon icon={faEnvelope} />}
                                                placeholder={"Email"}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <InputText
                                                type={'phone'}
                                                ref={phoneRef}
                                                text={phone} setText={setPhone}
                                                textError={phoneError} setTextError={setPhoneError}
                                                icon={<FontAwesomeIcon icon={faPhone} />}
                                                placeholder={"Téléphone"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <InputText
                                                type={'city'}
                                                ref={cityRef}
                                                text={city} setText={setCity}
                                                textError={cityError} setTextError={setCityError}
                                                icon={<FontAwesomeIcon icon={faCity} />}
                                                placeholder={"Ville"}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <InputText
                                                type={'cp'}
                                                ref={cpRef}
                                                text={cp} setText={setCp}
                                                textError={cpError} setTextError={setCpError}
                                                icon={<FontAwesomeIcon icon={faGlobe} />}
                                                placeholder={"Code postal"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <InputText
                                                type={'text'}
                                                ref={agencyRef}
                                                text={agency} setText={setAgency}
                                                textError={agencyError} setTextError={setAgencyError}
                                                icon={<FontAwesomeIcon icon={faBuilding} />}
                                                placeholder={"Agence"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <InputText
                                                type={'text'}
                                                ref={fonctionRef}
                                                text={fonction} setText={setFonction}
                                                textError={fonctionError} setTextError={setFonctionError}
                                                icon={<FontAwesomeIcon icon={faUserDoctor} />}
                                                placeholder={"Fonction"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <InputText
                                                type={'text'}
                                                ref={daysRef}
                                                text={days} setText={setDays}
                                                textError={daysError} setTextError={setDaysError}
                                                icon={<FontAwesomeIcon icon={faCalendarDays} />}
                                                placeholder={"Jour(s)"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <InputText
                                                type={'text'}
                                                ref={timeRef}
                                                text={time} setText={setTime}
                                                textError={timeError} setTextError={setTimeError}
                                                icon={<FontAwesomeIcon icon={faClock} />}
                                                placeholder={"Heure de rendez-vous"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <InputTextarea
                                                ref={observationsRef}
                                                text={observations} setText={setObservations}
                                                textError={observationsError} setTextError={setObservationsError}
                                                icon={<FontAwesomeIcon icon={faPen} />}
                                                placeholder={"Observations supplémentaires"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div id='contactFormButtonContainer'>
                                                <div id='contactFormButton' onClick={() => sendAppointment()}>
                                                    Envoyer
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </Fade>
                        </Col>
                    </Row>
                </div>
            </MobileView>

            <ToastContainer position='top-left' theme='light' />
        </>

    )

}

export default AppointmentsForm
