import React, { useState } from 'react'
import { BrowserView, MobileView } from 'react-device-detect'
import { Fade } from 'react-awesome-reveal'
import Footer from './Footer'
import logo from '../assets/logo.png'
import '../styles/Nav.css'
import '../styles/Cgu.css'

function Cgu() {

    const [smartphoneMenuDisplay, setSmartphoneMenuDisplay] = useState(false)

    return (

        <>

            <BrowserView>
                <nav id='nav'>
                  <Fade direction={'left'}>
                      <a id='navLogo' href="/">
                          <img src={logo} height={"40px"} alt={"logo"} />
                      </a>
                  </Fade>
                  <Fade direction={'right'}>
                      <div id='navLinks'>
                          <a className="navLink" href="/#presentation" className="navLink">Présentation</a>
                          <a className="navLink" href="/#solution" className="navLink">Nos solutions</a>
                          <a className="navLink" href="/#pricing" className="navLink">Tarifs</a>
                          <a className="navLink" href="/#contact" className="navLink">Contact</a>
                      </div>
                  </Fade>
                </nav>
                <div id='cgu'>
                    <div id='cguTitle'>CONDITIONS GÉNÉRALES D'UTILISATION</div>
                    <div className='cguTextParagraph'>
                        La société Acheter-louer.fr édite le site internet Call-ProImmo.fr
                    </div>
                    <div className='cguTextParagraph'>
                        Le site internet Call-ProImmo.fr (ci-après, le « site », est la propriété de la société acheter-louer.fr)
                    </div>
                    <div className='cguTextParagraph'>
                        Les présentes Conditions générales d'utilisation ont pour objet de fixer les conditions d'utilisation et les modalités de mise à disposition du site Call-ProImmo.fr.
                    </div>
                    <div className='cguTitleParagraph'>
                        Article 1 : Mentions légales
                    </div>
                    <div className='cguTextParagraph'>
                        Call-ProImmo.fr est édité par la SA Acheter-louer.fr, immatriculée au registre du commerce et des sociétés de Paris sous le numéro 394 052 211. Le siège social de acheter-louer.fr est situé au 2 rue de Tocqueville, 75017 Paris
                    </div>
                    <div className='cguTextParagraph'>
                        Pour nous contacter vous pouvez nous écrire à l'adresse ci-dessus, nous adresser un mail à contact@call-proimmo.fr ou encore nous appeler au 01 60 92 96 00.
                    </div>
                    <div className='cguTextParagraph'>
                        Directeur de publication : Laurent CAMPAGNOLO
                    </div>
                    <div className='cguTextParagraph'>
                        Call-ProImmo.fr est hébergé par INEDYS GROUP, 12 rue Gutenberg, 91620 NOZAY.
                    </div>
                    <div className='cguTitleParagraph'>
                        Article 2 : Définitions
                    </div>
                    <div className='cguTextParagraph'>
                        Call-ProImmo.fr met à la disposition de l'utilisateur via le site Call-ProImmo.fr un accès aux contenus et services. Chacun des termes mentionnés ci-dessous aura, dans les présentes conditions générales d'utilisation (« CGU ») la signification suivante :
                    </div>
                    <div className='cguTextParagraph'>
                        <b>Site</b> désigne le site accessible à l'adresse url https://www.call-proimmo.fr/
                    </div>
                    <div className='cguTextParagraph'>
                        <b>Utilisateur</b> désigne un utilisateur du Site ayant souscrit ou non un abonnement. Il peut s'agir d'une personne physique ou morale accédant au Site à des fins de simple consultation et sans utilisation d'un compte personnel.
                    </div>
                    <div className='cguTextParagraph'>
                        <b>Abonné</b> désigne un utilisateur d'un ou plusieurs produits ayant souscrit un abonnement.
                    </div>
                    <div className='cguTextParagraph'>
                        <b>Abonnement</b> désigne une offre commerciale émise par la Société, donnant accès aux contenus, aux produits ou aux offres commerciales proposés par le site.
                    </div>
                    <div className='cguTitleParagraph'>
                        Article 3 : Acceptation et opposabilité des conditions générales d'utilisation (CGU)
                    </div>
                    <div className='cguTextParagraph'>
                        En naviguant sur le Site, l'Utilisateur est présumé connaitre et accepter sans réserve les présentes conditions d'utilisation qui s'appliquent à l'ensemble des services disponibles sur le site, que ceux-ci soient fournis gratuitement ou sur abonnement.
                    </div>
                    <div className='cguTextParagraph'>
                        Les présentes CGU s'appliquent à toute déclinaison ou extension du site sur les réseaux sociaux.
                    </div>
                    <div className='cguTextParagraph'>
                        Acheter-louer.fr se réserve le droit de modifier à tout moment et sans préavis le présent document, pour se conformer à toute évolution réglementaire, éditoriale ou encore technique.
                    </div>
                    <div className='cguTextParagraph'>
                        L'Utilisateur peut à tout moment renoncer à utiliser le Site mais reste responsable de  toute utilisation antérieure.
                    </div>
                    <div className='cguTextParagraph'>
                        Sont exclus du champ d'application des présentes conditions générales d'utilisation les éventuels espaces et annonces publicitaires apparaissant sur le Site.
                    </div>
                    <div className='cguTitleParagraph'>
                        Article 4 : Fonctionnement et utilisation du site
                    </div>
                    <div className='cguTextParagraph'>
                        Call-ProImmo.fr est accessible 24 heures sur 24 et 7 jours sur 7. Pour des raisons de maintenance, de pannes ou en cas de force majeure, l'accès au site peut être interrompu ou suspendu par l'éditeur sans préavis ni justification.
                    </div>
                    <div className='cguTextParagraph'>
                        Le Site est accessible gratuitement à tout Utilisateur ou Visiteur disposant d'un accès internet.
                    </div>
                    <div className='cguTextParagraph'>
                        Tous les logiciels et matériels nécessaires à l'utilisation ou au fonctionnement des Services du Site, l'accès à l'Internet ou les frais de communication sont à sa charge. L'Utilisateur est seul responsable du bon fonctionnement de son équipement informatique et de son accès Internet.
                    </div>
                    <div className='cguTextParagraph'>
                        Le site propose aux utilisateurs les services suivants :
                    </div>
                    <div className='cguTextParagraph'>
                        - L'accès à une présentation de la société
                    </div>
                    <div className='cguTextParagraph'>
                        - L'accès à une présentation des services et produits de la société
                    </div>
                    <div className='cguTextParagraph'>
                        - L'accès aux tarifs de la société
                    </div>
                    <div className='cguTextParagraph'>
                        - L'accès à un formulaire de contact
                    </div>
                    <div className='cguTitleParagraph'>
                        Article 5 : Liens Hypertexte
                    </div>
                    <div className='cguTextParagraph'>
                        Le Site peut contenir des liens hypertextes pointant vers des pages web autres que celles de Call-ProImmo.fr. Ces liens ne sont pas contrôlés par Acheter-louer.fr.
                    </div>
                    <div className='cguTextParagraph'>
                        Acheter-louer.fr n'exerce aucun contrôle sur ces sites et décline toute responsabilité quant à leur accès, contenu ou utilisation, ainsi qu'aux dommages pouvant résulter de la consultation des informations présentes sur ces sites.
                    </div>
                    <div className='cguTitleParagraph'>
                        Article 6 : Propriété intellectuelle
                    </div>
                    <div className='cguTextParagraph'>
                        Acheter-louer.fr dispose des droits exclusifs de propriété intellectuelle sur l'ensemble des contenus du Site, des réalisations effectuées par Acheter-louer.fr : graphismes, logiciels, photographies, images, vidéos, sons, plans, noms, logos, marques, créations et œuvres protégeables diverses reproduits sur Call-ProImmo.fr.
                    </div>
                    <div className='cguTextParagraph'>
                        Conformément au Code de la propriété intellectuelle, l'accès au Site confère à l'Utilisateur un droit d'usage privé, non collectif et non exclusif des informations présentes sur le site.
                    </div>
                    <div className='cguTextParagraph'>
                        La reproduction d'un ou de plusieurs des contenus et services présents sur le Site, dans leur intégralité ou non, est soumise à des droits de reproduction et doit faire l'objet d'une demande d'autorisation préalable auprès de Acheter-louer.fr.
                    </div>
                    <div className='cguTextParagraph'>
                        Dans ce cas, toute utilisation à des usages commerciaux ou à des fins publicitaires est constitutive de contrefaçon et sanctionnée au titre de la propriété intellectuelle, sauf autorisation préalable de Acheter-louer.fr.
                    </div>
                    <div className='cguTextParagraph'>
                        Il est rappelé que la violation de l'un des droits d'auteur attaché aux contenus du Site constitue un délit de contrefaçon puni en France par l'article L 335-2 du Code de la propriété intellectuelle et passible de trois ans d'emprisonnement et 300 000 € d'amende.
                    </div>
                    <div className='cguTitleParagraph'>
                        Article 7 : Protection des données
                    </div>
                    <div className='cguTextParagraph'>
                        Acheter-louer.fr respecte la vie privée des Utilisateurs et se conforme strictement aux lois en vigueur sur la protection de la vie privée et des libertés individuelles. Ainsi, les données personnelles transmises par chaque Utilisateur sont destinées exclusivement aux services Acheter-louer.fr. Elles ne sont en aucun cas transmises à des tiers à des fins de publicité et de promotion sans l'accord préalable des utilisateurs.
                    </div>
                    <div className='cguTextParagraph'>
                        Conformément à la loi n°78-17 du 6 janvier relative à l'informatique, aux fichiers et aux libertés, la collecte et le traitement d'informations personnelles s'effectuent dans le respect de la vie privée. Suivant la loi Informatique et Libertés en date du 6 janvier 1978, articles 39 et 40, l'Utilisateur dispose du droit d'accéder, de rectifier, de supprimer et d'opposer ses données personnelles. L'exercice de ce droit s'effectue par le formulaire de contact.
                    </div>
                    <div className='cguTitleParagraph'>
                        Article 8 – Garantie et responsabilité
                    </div>
                    <div className='cguTextParagraph'>
                        La responsabilité de Acheter-louer.fr ne peut être retenue dans le cas où l'inscription n'a pas mené aux attentes de l'utilisateur.
                    </div>
                    <div className='cguTextParagraph'>
                        La responsabilité de Acheter-louer.fr ne peut être recherchée ni retenue en cas d'indisponibilité temporaire ou totale de tout ou partie de l'accès au service, d'une difficulté liée au temps de réponse, et d'une manière générale, d'un défaut de performance quelconque.
                    </div>
                    <div className='cguTextParagraph'>
                        La responsabilité de Acheter-louer.fr ne serait être retenue en cas de difficulté de diffusion du contenu ou plus généralement de toutes perturbations du réseau internet affectant l'utilisation du service. Acheter-louer.fr ne donne aucune garantie quant aux conditions de diffusion, à la qualité de diffusion et de transmission d'accessibilité du service.
                    </div>
                    <div className='cguTextParagraph'>
                        Call-ProImmo.fr s'efforce d'assurer l'exactitude et la mise à jour des informations diffusées sur le Site par lui, dont il se réserve le droit de corriger le contenu, à tout moment et sans préavis. Call-ProImmo.fr ne peut cependant en garantir l'exactitude, l'exhaustivité, la véracité ou l'absence de modification par un tiers.
                    </div>
                    <div className='cguTextParagraph'>
                        En outre Call-ProImmo.fr décline toute responsabilité, ce qui est accepté par l'utilisateur en cas d'erreur ou d'omission quant au contenu des pages du Site et à l'utilisation qui pourrait en être faite par les utilisateurs ou des tiers.
                    </div>
                    <div className='cguTitleParagraph'>
                        Article 9 - Cookie
                    </div>
                    <div className='cguTextParagraph'>
                        Lors des visites sur Site, l'installation automatique d'un cookie sur le logiciel de navigation de l'Utilisateur peut survenir.
                    </div>
                    <div className='cguTextParagraph'>
                        Les cookies correspondent à de petits fichiers déposés temporairement sur le terminal de l'ordinateur de l'Utilisateur pour identifier, par exemple ses centres d'intérêt. Les cookies enregistrent des informations relatives à la navigation de votre ordinateur sur un site (telles que par exemple les pages visitées ou encore les dates et heures de consultation), qui pourront être lues lors de vos visites ultérieures sur le Site
                    </div>
                    <div className='cguTextParagraph'>
                        Ces cookies sont nécessaires pour assurer l'accessibilité et améliorer les performances de navigation sur le Site. Ces fichiers ne comportent pas d'informations personnelles et ne peuvent pas être utilisés pour l'identification d'une personne.
                    </div>
                    <div className='cguTextParagraph'>
                        En naviguant sur le site, l'Utilisateur accepte les cookies. L'Utilisateur peut à tout moment choisir de désactiver ces cookies ou de les accepter au cas par cas via les paramètres du logiciel de navigation.
                    </div>
                    <div className='cguTextParagraph'>
                        Des cookies aussi peuvent être déposés par des sociétés tierces (par exemple des partenaires) pour identifier vos centres d'intérêt et éventuellement personnaliser l'offre publicitaire qui vous est adressée sur et en dehors de notre site. Ils peuvent être déposés quand vous naviguez sur notre site ou lorsque vous cliquez dans les espaces publicitaires de notre site.
                    </div>
                    <div className='cguTextParagraph'>
                        Dans le cadre de nos partenariats, nous veillons à ce que les sociétés partenaires respectent strictement la loi informatique et libertés du 6 janvier 1978 modifiée et s'engagent à mettre en œuvre des mesures appropriées de sécurisation et de protection de la confidentialité des données.
                    </div>
                    <div className='cguTitleParagraph'>
                        Article 10 - Durée du contrat
                    </div>
                    <div className='cguTextParagraph'>
                        Le présent contrat est valable pour une durée indéterminée. Le début de l'utilisation des services du site marque l'application du contrat à l'égard de l'Utilisateur.
                    </div>
                    <div className='cguTitleParagraph'>
                        Article 11 - Déclaration d'un contenu illicite
                    </div>
                    <div className='cguTextParagraph'>
                        Tout Utilisateur du site qui aurait connaissance d'un Contenu Prohibé, avéré ou suspecté, dans le cadre de l'utilisation des Services devra les déclarer sans délai, dans le respect des dispositions de l'article 6 de la loi du 22 Juin 2004 pour la confiance dans l'économie numérique, en indiquant :
                    </div>
                    <div className='cguTextParagraph'>
                        - Pour les personnes physiques : ses nom, prénom, profession, domicile, nationalité, date et lieu de naissance ;<br />
                        - Pour les personnes morales : sa forme, sa dénomination, son siège social et l'organe qui la représente légalement ;<br />
                        - la description des faits litigieux et leur localisation précise ;<br />
                        - les motifs pour lesquels le contenu doit être retiré, comprenant la mention des dispositions légales et des justifications de faits, le cas échéant en fonction d'un menu déroulant mis à la disposition des Utilisateurs ;<br />
                        - la copie de la correspondance adressée à l'auteur ou à l'éditeur des informations ou activités litigieuses demandant leur interruption, leur retrait ou leur modification, ou la justification de ce que l'auteur ou l'éditeur n'a pu être contacté.
                    </div>
                    <div className='cguTextParagraph'>
                        Il est rappelé que le fait de présenter un contenu ou une activité comme étant un Contenu Prohibé dans le but d'en obtenir le retrait ou d'en faire cesser la diffusion en sachant cette information inexacte est puni d'un an d'emprisonnement et de 150.000 euros d'amende.
                    </div>
                    <div className='cguTitleParagraph'>
                        Article 12 – Tribunaux et droit applicable
                    </div>
                    <div className='cguTextParagraph'>
                        Les présentes CGU sont régies par le droit français. L'absence de résolution à l'amiable des cas de litiges auxquels les CGU pourraient donner lieu, concernant tant leur validité, interprétation, exécution ou résiliation, leurs conséquences et leurs suites implique le recours aux tribunaux français compétents pour régler le contentieux.
                    </div>
                </div>
            </BrowserView>

            <MobileView>
                <nav id='nav'>
                    <div id='navLogo' onClick={() => window.scrollTo({ top: 0 })}>
                        <img src={logo} height={"30px"} alt={"logo"} />
                    </div>
                    <div className={smartphoneMenuDisplay ? "navSmartphoneBurglar is-open" : "navSmartphoneBurglar is-closed"} onClick={() => setSmartphoneMenuDisplay(!smartphoneMenuDisplay)}>
                        <div className="navSmartphoneBurgerIcon">
                            <div className="navSmartphoneBurgerIconContainer">
                                <span className="navSmartphoneBurgerBunTop"></span>
                                <span className="navSmartphoneBurgerFilling"></span>
                                <span className="navSmartphoneBurgerBunBot"></span>
                            </div>
                        </div>
                        <div className="burger-ring">
                            <svg className="svg-ring">
                                <path className="path" fill="none" stroke="#000000" strokeMiterlimit="10" strokeWidth="4" d="M 34 2 C 16.3 2 2 16.3 2 34 s 14.3 32 32 32 s 32 -14.3 32 -32 S 51.7 2 34 2" />
                            </svg>
                        </div>
                        <svg width="0" height="0">
                            <mask id="mask">
                                <path xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#ff0000" strokeMiterlimit="10" strokeWidth="4" d="M 34 2 c 11.6 0 21.8 6.2 27.4 15.5 c 2.9 4.8 5 16.5 -9.4 16.5 h -4" />
                            </mask>
                        </svg>
                        <div className="path-burger">
                            <div className="animate-path">
                                <div className="path-rotation"></div>
                            </div>
                        </div>
                    </div>
                    {smartphoneMenuDisplay ?
                        <div id='navSmartphoneBurger'>
                            <a href="/#presentation" className="navSmartphoneBurgerLink">Présentation</a>
                            <a href="/#solution" className="navSmartphoneBurgerLink">Nos solutions</a>
                            <a href="/#pricing" className="navSmartphoneBurgerLink">Tarifs</a>
                            <a href="/#contact" className="navSmartphoneBurgerLink">Contact</a>
                        </div>
                        : null
                    }
                </nav>
                <div id='cguSmartphone'>
                    <div id='cguTitle'>CONDITIONS GÉNÉRALES D'UTILISATION</div>
                    <div className='cguTextParagraph'>
                        La société Acheter-louer.fr édite le site internet Call-ProImmo.fr
                    </div>
                    <div className='cguTextParagraph'>
                        Le site internet Call-ProImmo.fr (ci-après, le « site », est la propriété de la société acheter-louer.fr)
                    </div>
                    <div className='cguTextParagraph'>
                        Les présentes Conditions générales d'utilisation ont pour objet de fixer les conditions d'utilisation et les modalités de mise à disposition du site Call-ProImmo.fr.
                    </div>
                    <div className='cguTitleParagraph'>
                        Article 1 : Mentions légales
                    </div>
                    <div className='cguTextParagraph'>
                        Call-ProImmo.fr est édité par la SA Acheter-louer.fr, immatriculée au registre du commerce et des sociétés de Paris sous le numéro 394 052 211. Le siège social de acheter-louer.fr est situé au 2 rue de Tocqueville, 75017 Paris
                    </div>
                    <div className='cguTextParagraph'>
                        Pour nous contacter vous pouvez nous écrire à l'adresse ci-dessus, nous adresser un mail à contact@call-proimmo.fr ou encore nous appeler au 01 60 92 96 00.
                    </div>
                    <div className='cguTextParagraph'>
                        Directeur de publication : Laurent CAMPAGNOLO
                    </div>
                    <div className='cguTextParagraph'>
                        Call-ProImmo.fr est hébergé par INEDYS GROUP, 12 rue Gutenberg, 91620 NOZAY.
                    </div>
                    <div className='cguTitleParagraph'>
                        Article 2 : Définitions
                    </div>
                    <div className='cguTextParagraph'>
                        Call-ProImmo.fr met à la disposition de l'utilisateur via le site Call-ProImmo.fr un accès aux contenus et services. Chacun des termes mentionnés ci-dessous aura, dans les présentes conditions générales d'utilisation (« CGU ») la signification suivante :
                    </div>
                    <div className='cguTextParagraph'>
                        <b>Site</b> désigne le site accessible à l'adresse url https://www.call-proimmo.fr/
                    </div>
                    <div className='cguTextParagraph'>
                        <b>Utilisateur</b> désigne un utilisateur du Site ayant souscrit ou non un abonnement. Il peut s'agir d'une personne physique ou morale accédant au Site à des fins de simple consultation et sans utilisation d'un compte personnel.
                    </div>
                    <div className='cguTextParagraph'>
                        <b>Abonné</b> désigne un utilisateur d'un ou plusieurs produits ayant souscrit un abonnement.
                    </div>
                    <div className='cguTextParagraph'>
                        <b>Abonnement</b> désigne une offre commerciale émise par la Société, donnant accès aux contenus, aux produits ou aux offres commerciales proposés par le site.
                    </div>
                    <div className='cguTitleParagraph'>
                        Article 3 : Acceptation et opposabilité des conditions générales d'utilisation (CGU)
                    </div>
                    <div className='cguTextParagraph'>
                        En naviguant sur le Site, l'Utilisateur est présumé connaitre et accepter sans réserve les présentes conditions d'utilisation qui s'appliquent à l'ensemble des services disponibles sur le site, que ceux-ci soient fournis gratuitement ou sur abonnement.
                    </div>
                    <div className='cguTextParagraph'>
                        Les présentes CGU s'appliquent à toute déclinaison ou extension du site sur les réseaux sociaux.
                    </div>
                    <div className='cguTextParagraph'>
                        Acheter-louer.fr se réserve le droit de modifier à tout moment et sans préavis le présent document, pour se conformer à toute évolution réglementaire, éditoriale ou encore technique.
                    </div>
                    <div className='cguTextParagraph'>
                        L'Utilisateur peut à tout moment renoncer à utiliser le Site mais reste responsable de  toute utilisation antérieure.
                    </div>
                    <div className='cguTextParagraph'>
                        Sont exclus du champ d'application des présentes conditions générales d'utilisation les éventuels espaces et annonces publicitaires apparaissant sur le Site.
                    </div>
                    <div className='cguTitleParagraph'>
                        Article 4 : Fonctionnement et utilisation du site
                    </div>
                    <div className='cguTextParagraph'>
                        Call-ProImmo.fr est accessible 24 heures sur 24 et 7 jours sur 7. Pour des raisons de maintenance, de pannes ou en cas de force majeure, l'accès au site peut être interrompu ou suspendu par l'éditeur sans préavis ni justification.
                    </div>
                    <div className='cguTextParagraph'>
                        Le Site est accessible gratuitement à tout Utilisateur ou Visiteur disposant d'un accès internet.
                    </div>
                    <div className='cguTextParagraph'>
                        Tous les logiciels et matériels nécessaires à l'utilisation ou au fonctionnement des Services du Site, l'accès à l'Internet ou les frais de communication sont à sa charge. L'Utilisateur est seul responsable du bon fonctionnement de son équipement informatique et de son accès Internet.
                    </div>
                    <div className='cguTextParagraph'>
                        Le site propose aux utilisateurs les services suivants :
                    </div>
                    <div className='cguTextParagraph'>
                        - L'accès à une présentation de la société
                    </div>
                    <div className='cguTextParagraph'>
                        - L'accès à une présentation des services et produits de la société
                    </div>
                    <div className='cguTextParagraph'>
                        - L'accès aux tarifs de la société
                    </div>
                    <div className='cguTextParagraph'>
                        - L'accès à un formulaire de contact
                    </div>
                    <div className='cguTitleParagraph'>
                        Article 5 : Liens Hypertexte
                    </div>
                    <div className='cguTextParagraph'>
                        Le Site peut contenir des liens hypertextes pointant vers des pages web autres que celles de Call-ProImmo.fr. Ces liens ne sont pas contrôlés par Acheter-louer.fr.
                    </div>
                    <div className='cguTextParagraph'>
                        Acheter-louer.fr n'exerce aucun contrôle sur ces sites et décline toute responsabilité quant à leur accès, contenu ou utilisation, ainsi qu'aux dommages pouvant résulter de la consultation des informations présentes sur ces sites.
                    </div>
                    <div className='cguTitleParagraph'>
                        Article 6 : Propriété intellectuelle
                    </div>
                    <div className='cguTextParagraph'>
                        Acheter-louer.fr dispose des droits exclusifs de propriété intellectuelle sur l'ensemble des contenus du Site, des réalisations effectuées par Acheter-louer.fr : graphismes, logiciels, photographies, images, vidéos, sons, plans, noms, logos, marques, créations et œuvres protégeables diverses reproduits sur Call-ProImmo.fr.
                    </div>
                    <div className='cguTextParagraph'>
                        Conformément au Code de la propriété intellectuelle, l'accès au Site confère à l'Utilisateur un droit d'usage privé, non collectif et non exclusif des informations présentes sur le site.
                    </div>
                    <div className='cguTextParagraph'>
                        La reproduction d'un ou de plusieurs des contenus et services présents sur le Site, dans leur intégralité ou non, est soumise à des droits de reproduction et doit faire l'objet d'une demande d'autorisation préalable auprès de Acheter-louer.fr.
                    </div>
                    <div className='cguTextParagraph'>
                        Dans ce cas, toute utilisation à des usages commerciaux ou à des fins publicitaires est constitutive de contrefaçon et sanctionnée au titre de la propriété intellectuelle, sauf autorisation préalable de Acheter-louer.fr.
                    </div>
                    <div className='cguTextParagraph'>
                        Il est rappelé que la violation de l'un des droits d'auteur attaché aux contenus du Site constitue un délit de contrefaçon puni en France par l'article L 335-2 du Code de la propriété intellectuelle et passible de trois ans d'emprisonnement et 300 000 € d'amende.
                    </div>
                    <div className='cguTitleParagraph'>
                        Article 7 : Protection des données
                    </div>
                    <div className='cguTextParagraph'>
                        Acheter-louer.fr respecte la vie privée des Utilisateurs et se conforme strictement aux lois en vigueur sur la protection de la vie privée et des libertés individuelles. Ainsi, les données personnelles transmises par chaque Utilisateur sont destinées exclusivement aux services Acheter-louer.fr. Elles ne sont en aucun cas transmises à des tiers à des fins de publicité et de promotion sans l'accord préalable des utilisateurs.
                    </div>
                    <div className='cguTextParagraph'>
                        Conformément à la loi n°78-17 du 6 janvier relative à l'informatique, aux fichiers et aux libertés, la collecte et le traitement d'informations personnelles s'effectuent dans le respect de la vie privée. Suivant la loi Informatique et Libertés en date du 6 janvier 1978, articles 39 et 40, l'Utilisateur dispose du droit d'accéder, de rectifier, de supprimer et d'opposer ses données personnelles. L'exercice de ce droit s'effectue par le formulaire de contact.
                    </div>
                    <div className='cguTitleParagraph'>
                        Article 8 – Garantie et responsabilité
                    </div>
                    <div className='cguTextParagraph'>
                        La responsabilité de Acheter-louer.fr ne peut être retenue dans le cas où l'inscription n'a pas mené aux attentes de l'utilisateur.
                    </div>
                    <div className='cguTextParagraph'>
                        La responsabilité de Acheter-louer.fr ne peut être recherchée ni retenue en cas d'indisponibilité temporaire ou totale de tout ou partie de l'accès au service, d'une difficulté liée au temps de réponse, et d'une manière générale, d'un défaut de performance quelconque.
                    </div>
                    <div className='cguTextParagraph'>
                        La responsabilité de Acheter-louer.fr ne serait être retenue en cas de difficulté de diffusion du contenu ou plus généralement de toutes perturbations du réseau internet affectant l'utilisation du service. Acheter-louer.fr ne donne aucune garantie quant aux conditions de diffusion, à la qualité de diffusion et de transmission d'accessibilité du service.
                    </div>
                    <div className='cguTextParagraph'>
                        Call-ProImmo.fr s'efforce d'assurer l'exactitude et la mise à jour des informations diffusées sur le Site par lui, dont il se réserve le droit de corriger le contenu, à tout moment et sans préavis. Call-ProImmo.fr ne peut cependant en garantir l'exactitude, l'exhaustivité, la véracité ou l'absence de modification par un tiers.
                    </div>
                    <div className='cguTextParagraph'>
                        En outre Call-ProImmo.fr décline toute responsabilité, ce qui est accepté par l'utilisateur en cas d'erreur ou d'omission quant au contenu des pages du Site et à l'utilisation qui pourrait en être faite par les utilisateurs ou des tiers.
                    </div>
                    <div className='cguTitleParagraph'>
                        Article 9 - Cookie
                    </div>
                    <div className='cguTextParagraph'>
                        Lors des visites sur Site, l'installation automatique d'un cookie sur le logiciel de navigation de l'Utilisateur peut survenir.
                    </div>
                    <div className='cguTextParagraph'>
                        Les cookies correspondent à de petits fichiers déposés temporairement sur le terminal de l'ordinateur de l'Utilisateur pour identifier, par exemple ses centres d'intérêt. Les cookies enregistrent des informations relatives à la navigation de votre ordinateur sur un site (telles que par exemple les pages visitées ou encore les dates et heures de consultation), qui pourront être lues lors de vos visites ultérieures sur le Site
                    </div>
                    <div className='cguTextParagraph'>
                        Ces cookies sont nécessaires pour assurer l'accessibilité et améliorer les performances de navigation sur le Site. Ces fichiers ne comportent pas d'informations personnelles et ne peuvent pas être utilisés pour l'identification d'une personne.
                    </div>
                    <div className='cguTextParagraph'>
                        En naviguant sur le site, l'Utilisateur accepte les cookies. L'Utilisateur peut à tout moment choisir de désactiver ces cookies ou de les accepter au cas par cas via les paramètres du logiciel de navigation.
                    </div>
                    <div className='cguTextParagraph'>
                        Des cookies aussi peuvent être déposés par des sociétés tierces (par exemple des partenaires) pour identifier vos centres d'intérêt et éventuellement personnaliser l'offre publicitaire qui vous est adressée sur et en dehors de notre site. Ils peuvent être déposés quand vous naviguez sur notre site ou lorsque vous cliquez dans les espaces publicitaires de notre site.
                    </div>
                    <div className='cguTextParagraph'>
                        Dans le cadre de nos partenariats, nous veillons à ce que les sociétés partenaires respectent strictement la loi informatique et libertés du 6 janvier 1978 modifiée et s'engagent à mettre en œuvre des mesures appropriées de sécurisation et de protection de la confidentialité des données.
                    </div>
                    <div className='cguTitleParagraph'>
                        Article 10 - Durée du contrat
                    </div>
                    <div className='cguTextParagraph'>
                        Le présent contrat est valable pour une durée indéterminée. Le début de l'utilisation des services du site marque l'application du contrat à l'égard de l'Utilisateur.
                    </div>
                    <div className='cguTitleParagraph'>
                        Article 11 - Déclaration d'un contenu illicite
                    </div>
                    <div className='cguTextParagraph'>
                        Tout Utilisateur du site qui aurait connaissance d'un Contenu Prohibé, avéré ou suspecté, dans le cadre de l'utilisation des Services devra les déclarer sans délai, dans le respect des dispositions de l'article 6 de la loi du 22 Juin 2004 pour la confiance dans l'économie numérique, en indiquant :
                    </div>
                    <div className='cguTextParagraph'>
                        - Pour les personnes physiques : ses nom, prénom, profession, domicile, nationalité, date et lieu de naissance ;<br />
                        - Pour les personnes morales : sa forme, sa dénomination, son siège social et l'organe qui la représente légalement ;<br />
                        - la description des faits litigieux et leur localisation précise ;<br />
                        - les motifs pour lesquels le contenu doit être retiré, comprenant la mention des dispositions légales et des justifications de faits, le cas échéant en fonction d'un menu déroulant mis à la disposition des Utilisateurs ;<br />
                        - la copie de la correspondance adressée à l'auteur ou à l'éditeur des informations ou activités litigieuses demandant leur interruption, leur retrait ou leur modification, ou la justification de ce que l'auteur ou l'éditeur n'a pu être contacté.
                    </div>
                    <div className='cguTextParagraph'>
                        Il est rappelé que le fait de présenter un contenu ou une activité comme étant un Contenu Prohibé dans le but d'en obtenir le retrait ou d'en faire cesser la diffusion en sachant cette information inexacte est puni d'un an d'emprisonnement et de 150.000 euros d'amende.
                    </div>
                    <div className='cguTitleParagraph'>
                        Article 12 – Tribunaux et droit applicable
                    </div>
                    <div className='cguTextParagraph'>
                        Les présentes CGU sont régies par le droit français. L'absence de résolution à l'amiable des cas de litiges auxquels les CGU pourraient donner lieu, concernant tant leur validité, interprétation, exécution ou résiliation, leurs conséquences et leurs suites implique le recours aux tribunaux français compétents pour régler le contentieux.
                    </div>
                </div>

            </MobileView>

            <Footer />
        </>

    )

}

export default Cgu
