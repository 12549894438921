import React, { useEffect, useState } from 'react'
import { Fade } from 'react-awesome-reveal'
import logo from '../assets/logo.png'
import '../styles/Footer.css'
import { BrowserView, MobileView } from 'react-device-detect'
import axios from 'axios'

function Footer() {

    const [capital, setCapital] = useState(null)

    useEffect(() => {

        axios.get('https://landings.acheterlouerpro.fr/api/capital').then((response) => {
            if (response.data.length > 0) {
                setCapital(response.data)
            }
        }).catch((error) => {
            console.log(error.toJSON())
        })

    }, [])

    return (

        <>

            <BrowserView>
                <div id='footer'>
                    <Fade direction={'left'}>
                        <div id='footerLogo' onClick={() => window.scrollTo({ top: 0 })}>
                            <img src={logo} height={"40px"} alt={"logo"} />
                        </div>
                    </Fade>
                    <Fade direction={'right'}>
                        <div id='footerText'>
                            Call-ProImmo est une activité de la société acheter-louer.fr SA au capital de {capital} €
                            <br />
                            RCS Paris 394 052 211 Siège social : 2 rue de Tocqueville 75017 Paris
                        </div>
                        <div id='footerLink'>
                            <a href='/cgu'>Conditions générales d'utilisation</a>
                        </div>
                    </Fade>
                </div>
            </BrowserView>

            <MobileView>
                <div id='footerSmartphone'>
                    <Fade direction={'left'}>
                        <div id='footerLogo' onClick={() => window.scrollTo({ top: 0 })}>
                            <img src={logo} height={"40px"} alt={"logo"} />
                        </div>
                    </Fade>
                    <Fade direction={'right'}>
                        <div id='footerSmartphoneText'>
                            Call-ProImmo est une activité de la société acheter-louer.fr SA au capital de {capital} €
                            <br />
                            RCS Paris 394 052 211 Siège social : 2 rue de Tocqueville 75017 Paris
                        </div>
                        <div id='footerSmartphoneLink'>
                            <a href='/cgu'>Conditions générales d'utilisation</a>
                        </div>
                    </Fade>
                </div>
            </MobileView>

        </>



    )

}

export default Footer
