import React from 'react'
import SwitchSelector from 'react-switch-selector'
import '../../styles/Input/InputLargeSwitchSelector.css'

function InputLargeSwitchSelector({ data, setValue }) {

    return (

        <div className='switchSelectorContainer'>
            <div className='largeSwitchSelector'>
                <SwitchSelector
                    onChange={(value) => setValue(value)}
                    options={data}
                    backgroundColor={'#dadada'}
                    fontColor={'#525252'}
                    selectedBackgroundColor={'#525252'}
                    selectedFontColor={'#ffffff'}
                    fontSize={16}
                />
            </div>
        </div>

    )

}

export default InputLargeSwitchSelector
