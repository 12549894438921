import React from 'react'
import { BrowserView, MobileView } from 'react-device-detect'
import { Fade } from 'react-awesome-reveal'
import headerImage from '../assets/headerImage.png'
import headerIcon from '../assets/headerIcon.png'
import '../styles/Header.css'

function Header() {

    return (

        <>
            <BrowserView>
                <header id='header'>
                    <Fade direction='right'>
                        <img src={headerImage} id='headerImage' alt="headerImage" />
                    </Fade>
                    <Fade>
                        <div id='headerTitle'>
                            Votre
                            <br />
                            <b>Call-Center BtoB</b>
                            <br />
                            Immobilier - Habitat
                            <br />
                            & PropTech
                        </div>
                    </Fade>
                    <Fade direction='left'>
                        <div id='headerBand'>
                            <img src={headerIcon} id='headerBandIcon' alt="headerIcon" />
                            <div id='headerBandText'>
                                CAPTEZ et GAGNER de nouveaux CLIENTS
                                <br />
                                ACCELEREZ votre DEVELOPPEMENT
                            </div>
                        </div>
                    </Fade>
                </header>
            </BrowserView>

            <MobileView>
                <header id='header'>
                    <Fade delay={600}>
                        <div id='headerSmartphoneTitle'>
                            Votre
                            <br />
                            <b>Call-Center BtoB</b>
                            <br />
                            Immobilier - Habitat
                            <br />
                            & PropTech
                        </div>
                    </Fade>
                    <Fade direction='right'>
                        <img src={headerImage} id='headerSmartphoneImage' alt="headerImage" />
                    </Fade>
                    <Fade direction='left'>
                        <div id='headerSmartphoneBand'>
                            <img src={headerIcon} id='headerSmartphoneBandIcon' alt="headerIcon" />
                            <div id='headerSmartphoneBandText'>
                                CAPTEZ et GAGNER de nouveaux CLIENTS
                                <br />
                                ACCELEREZ votre DEVELOPPEMENT
                            </div>
                        </div>
                    </Fade>
                </header>
            </MobileView>
        </>


    )

}

export default Header