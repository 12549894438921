import React from 'react'
import { BrowserView, MobileView } from 'react-device-detect'
import { Fade } from 'react-awesome-reveal'
import presentationImage from '../assets/presentationImage.jpg'
import '../styles/Presentation.css'
import { Parallax } from 'react-scroll-parallax'

function Presentation() {

    return (

        <>

            <BrowserView>
                <Parallax speed={30}>
                    <div id='presentation'>
                        <Fade direction={'left'}>
                            <img src={presentationImage} id='presentationImage' alt="presentationImage" />
                        </Fade>
                        <Fade direction={'left'} delay={300}>
                            <div id='presentationText'>
                                <div className='presentationTextParagraph'>
                                    Call-<span className='presentationTextRed'>Pro</span>Immo est le Call-Center dédiée à l’immobilier et l’habitat, avec une équipe de téléconseillers expérimentés et spécialistes du secteur de l’immobilier et de l’habitat, qui réalise des opérations
                                    de prospection téléphonique auprès des professionnels de l’immobilier et de l’habitat.
                                </div>
                                <div className='presentationTextParagraph'>
                                    Call-<span className='presentationTextRed'>Pro</span>Immo, permet aux acteurs professionnels et aux PropTech souhaitant proposer des solutions, des services et des produits, aux professionnels de l’immobilier et de l’habitat, de réaliser des campagnes de télémarketing, avec des téléconseillers
                                    dédiés spécialistes du secteur de l’immobilier et de l’habitat.
                                </div>
                                <div className='presentationTextParagraph'>
                                    Nous utilisons des bases de données qualifiées.
                                </div>
                                <div className='presentationTextParagraph'>
                                    Call-<span className='presentationTextRed'>Pro</span>Immo est une activité du groupe acheter-louer.fr
                                </div>
                            </div>
                        </Fade>
                    </div>
                </Parallax>
            </BrowserView>

            <MobileView>
                <Parallax speed={30}>
                    <div id='presentationSmartphone'>
                        <Fade direction={'left'}>
                            <div id='presentationTextTitle'>PRESENTATION</div>
                            <img src={presentationImage} id='presentationSmartphoneImage' alt="presentationImage" />
                        </Fade>
                        <Fade direction={'left'} delay={300}>
                            <div id='presentationSmartphoneText'>
                                <div className='presentationSmartphoneTextParagraph'>
                                    Call-<span className='presentationTextRed'>Pro</span>Immo est le Call-Center dédiée à l’immobilier et l’habitat, avec une équipe de téléconseillers expérimentés et spécialistes du secteur de l’immobilier et de l’habitat, qui réalise des opérations
                                    de prospection téléphonique auprès des professionnels de l’immobilier et de l’habitat.
                                </div>
                                <div className='presentationSmartphoneTextParagraph'>
                                    Call-<span className='presentationTextRed'>Pro</span>Immo, permet aux acteurs professionnels et aux PropTech souhaitant proposer des solutions, des services et des produits, aux professionnels de l’immobilier et de l’habitat, de réaliser des campagnes de télémarketing, avec des téléconseillers
                                    dédiés spécialistes du secteur de l’immobilier et de l’habitat.
                                </div>
                                <div className='presentationSmartphoneTextParagraph'>
                                    Nous utilisons des bases de données qualifiées.
                                </div>
                                <div className='presentationSmartphoneTextParagraph'>
                                    Call-<span className='presentationTextRed'>Pro</span>Immo est une activité du groupe acheter-louer.fr
                                </div>
                            </div>
                        </Fade>
                    </div>
                </Parallax>
            </MobileView>

        </>

    )

}

export default Presentation